<template>
    <div class="dashboard-layout">
        <div class="dashboard-layout__header">
            <div class="dashboard-layout__title">
                {{ $route.meta.title }}
            </div>

            <!-- Показатели -->
            <div class="dashboard-layout__statistics">

            </div>

            <!-- Пользователь -->
            <div class="dashboard-layout__user">
                <div class="dashboard-layout__user-name">
                    {{ $userStore.email }}
                </div>
                <div class="dashboard-layout__user-icons">
                    <div class="dashboard-layout__notifications-icon"></div>
                    <div
                        class="dashboard-layout__menu-icon"
                        @click.prevent="toggleMenu"
                    >
                        <div class="dashboard-layout__menu-container" v-if="menu.isVisible">
                            <router-link :to="{name: 'dashboard-main'}">События</router-link>
                            <router-link :to="{name: 'statistics'}" v-if="$checkPermissions([1])">Статистика</router-link>
                            <router-link :to="{name: 'users'}" v-if="$checkPermissions([1,2])">Пользователи</router-link>
                            <router-link :to="{name: 'credit-types'}" v-if="$checkPermissions([1])">Типы расходов</router-link>
                            <a href="#" @click.prevent="logout">Выход</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-layout__stats" v-if="$userStore.roleId == 1 || $userStore.roleId == 2">
            <div class="dashboard-layout__stats-group">
                <div class="dashboard-layout__stats-group-name">
                    Исполненные
                </div>
                <div class="dashboard-layout__stats-group-content">
                    <div class="dashboard-layout__stats-group-column">
                        <span class="dashboard-layout__stats-group-column-name">Зачисления</span>
                        <span class="dashboard-layout__stats-group-column-value">{{ $convertToCurrency(statistics.completed.debit) }}</span>
                    </div>
                    <div class="dashboard-layout__stats-group-column">
                        <span class="dashboard-layout__stats-group-column-name">Расходы</span>
                        <span class="dashboard-layout__stats-group-column-value">{{ $convertToCurrency(statistics.completed.credit) }}</span>
                    </div>
                    <div class="dashboard-layout__stats-group-column">
                        <span class="dashboard-layout__stats-group-column-name">Остаток</span>
                        <span class="dashboard-layout__stats-group-column-value">{{ $convertToCurrency(statistics.completed.balance) }}</span>
                    </div>
                </div>
            </div>

            <div class="dashboard-layout__stats-group">
                <div class="dashboard-layout__stats-group-name">
                    Общая статистика
                </div>
                <div class="dashboard-layout__stats-group-content">
                    <div class="dashboard-layout__stats-group-column">
                        <span class="dashboard-layout__stats-group-column-name">Зачисления</span>
                        <span class="dashboard-layout__stats-group-column-value">{{ $convertToCurrency(statistics.plan.debit) }}</span>
                    </div>
                    <div class="dashboard-layout__stats-group-column">
                        <span class="dashboard-layout__stats-group-column-name">Расходы</span>
                        <span class="dashboard-layout__stats-group-column-value">{{ $convertToCurrency(statistics.plan.credit) }}</span>
                    </div>
                    <div class="dashboard-layout__stats-group-column">
                        <span class="dashboard-layout__stats-group-column-name">Остаток</span>
                        <span class="dashboard-layout__stats-group-column-value">{{ $convertToCurrency(statistics.plan.balance) }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-layout__content">
            <RouterView />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            statistics: {
                plan: {
                    debit: 0,
                    credit: 0,
                    balance: 0
                },
                completed: {
                    debit: 0,
                    credit: 0,
                    balance: 0
                }
            },
            menu: {
                isVisible: false
            },
            checkStatisticsInterval: null
        }
    },
    methods: {
        async getStatistics() {
            if (!this.$checkPermissions([1,2])) return false
            try {
                const {data} = await this.$api.get('/v2/statistics/all')
                this.statistics = data
            } catch (e) {
                this.$catch(e, 'Ошибка получения статистики')
            }
        },
        toggleMenu() {
            this.menu.isVisible = !this.menu.isVisible
        },
        logout() {
            this.$userStore.name = null;
            this.$userStore.email = null;
            this.$userStore.token = null;
            this.$userStore.roleId = null;

            this.$router.push('/')
        },
        async checkStatistics() {
            if(this.$notificationsStore.statistics) {
                await this.getStatistics()
                this.$notificationsStore.statistics = false
            }
        }
    },
    async mounted() {
        await this.getStatistics()
        this.checkStatisticsInterval = setInterval(this.checkStatistics, 1000)
    }
}
</script>
