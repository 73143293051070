<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window" v-if="componentIsReady">
      <div class="ui-modal__heading">
        <h3>Создание события</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form
        class="ui-modal__form"
        autocomplete="off"
        @submit.prevent="createEvent"
      >
        <div class="ui-form-group">
          <label class="ui-label">Дата</label>
          <VueDatePicker
            locale="ru"
            v-model="eventData.event.createdAt"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">Название события</label>
          <input
            type="text"
            class="ui-input"
            placeholder="Название события"
            v-model="eventData.event.name"
            required
          />
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">Менеджер</label>
          <MultiSelect
            v-model="multiselect.managerObject"
            tag-placeholder="Выберите менеджера"
            placeholder="Начните вводить имя менеджера"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="selectManager"
            @remove="removeManager"
            required
          />
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">Наблюдатели</label>
          <MultiSelect
            v-model="multiselect.canView"
            tag-placeholder="Кто может просматривать событие"
            placeholder="Начните вводить имя сотрудника"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            :multiple="true"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="changeCanView"
            @remove="changeCanView"
            required
          />
        </div>
        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">Редакторы</label>
          <MultiSelect
            v-model="multiselect.canEdit"
            tag-placeholder="Кто может редактировать событие"
            placeholder="Начните вводить имя сотрудника"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            :multiple="true"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="changeCanEdit"
            @remove="changeCanEdit"
            required
          />
        </div>

        <div class="ui-form-group" v-if="multiselect.eventStatuses.length">
          <label class="ui-label">Статус события</label>
          <MultiSelect
            v-model="multiselect.eventStatusObject"
            tag-placeholder="Выберите статус"
            placeholder="Выберите статус"
            label="name"
            track-by="id"
            :options="multiselect.eventStatuses"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :searchable="false"
            :allow-empty="false"
            @select="selectEventStatus"
            @remove="removeEventStatus"
            required
          />
        </div>

        <div class="ui-form-group">
          <label class="ui-label">Сумма контракта</label>
          <input
            type="number"
            class="ui-input"
            placeholder="Сумма контракта"
            v-model="eventData.event.debit"
            min="0"
            step="0.01"
            required
          />
        </div>
        <div
          class="ui-form-group"
          v-if="multiselect.vendors.length"
        >
          <label class="ui-label">Поставщик</label>
          <MultiSelect
            v-model="multiselect.vendorObject"
            tag-placeholder="Выберите поставщика"
            placeholder="Начните вводить имя поставщика"
            label="name"
            track-by="id"
            :options="multiselect.vendors"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="selectVendor"
            @remove="removeVendor"
            required
          />
        </div>

        <div class="ui-form-group">
          <label class="ui-label">Клиент</label>
          <MultiSelect
            v-model="multiselect.clientObject"
            tag-placeholder="Выберите клиента"
            placeholder="Начните вводить название клиента"
            label="company"
            track-by="id"
            :options="multiselect.clients"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="selectClient"
            @remove="removeClient"
            required
          />
        </div>
        <h3 v-if="eventData.event.clientId === 0">Новый клиент</h3>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">Email</label>
          <input
            type="email"
            class="ui-input"
            placeholder="Email"
            v-model="eventData.client.email"
            autocomplete="off"
            required
            :readonly="activeFields.email"
            @focusin="activeFields.email = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">Пароль</label>
          <input
            type="password"
            class="ui-input"
            placeholder="Пароль"
            v-model="eventData.client.password"
            autocomplete="off"
            required
            :readonly="activeFields.password"
            @focus="activeFields.password = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">Имя клиента</label>
          <input
            type="text"
            class="ui-input"
            placeholder="Имя"
            v-model="eventData.client.name"
            autocomplete="off"
            required
            :readonly="activeFields.name"
            @focus="activeFields.name = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">Телефон</label>
          <MaskInput
            class="ui-input"
            placeholder="Телефон"
            v-model="eventData.client.phone"
            autocomplete="off"
            required
            mask="+# (###) ###-##-##"
            :readonly="activeFields.phone"
            @focus="activeFields.phone = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">Адрес</label>
          <input
            type="text"
            class="ui-input"
            placeholder="Адрес"
            v-model="eventData.client.address"
            autocomplete="off"
            required
            :readonly="activeFields.address"
            @focus="activeFields.address = false"
          />
        </div>
        <div class="ui-form-group" v-if="eventData.event.clientId === 0">
          <label class="ui-label">Компания</label>
          <input
            type="text"
            class="ui-input"
            placeholder="Компания"
            v-model="eventData.client.company"
            autocomplete="off"
            required
            :readonly="activeFields.company"
            @focus="activeFields.company = false"
          />
        </div>
        <button type="submit" class="ui-button" v-text="'Сохранить'" />
      </form>
    </div>
  </div>
</template>

<script>

export default {
  props: ['eventId'],
  data() {
    return {
      multiselect: {
        clients: [],
        clientObject: null,
        managers: [],
        managerObject: null,
        eventStatuses: [],
        eventStatusObject: null,
        vendors: [],
        vendorObject: null,
        canView: [],
        canEdit: []
      },
      eventData: {
        event: {
          name: null,
          managerId: null,
          debit: null,
          eventStatusId: null,
          clientId: null,
          vendorId: null,
          createdAt: Date.now(),
          canView: [],
          canEdit: []
        },
        client: {
          email: null,
          password: null,
          name: null,
          phone: null,
          address: null
        }
      },
      activeFields: {
        email: true,
        password: true,
        name: true,
        phone: true,
        address: true,
        company: true
      },
      eventStatuses: [],
      clientList: [],
      managerList: [],
      vendorList: [],
      isLoading: false,
      componentIsReady: false
    }
  },
  methods: {
    selectClient(client) {
      this.eventData.event.clientId = client.id
    },
    removeClient() {
      this.eventData.event.clientId = null
    },
    selectManager(manager) {
      this.eventData.event.managerId = manager.id
    },
    removeManager() {
      this.eventData.event.managerId = null
    },
    selectEventStatus(status) {
      this.eventData.event.eventStatusId = status.id
    },
    removeEventStatus() {
      this.eventData.event.eventStatusId = null
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get('/v1/event-status/list')
        this.eventStatuses = data
      } catch (e) {
        this.$catch(e, 'Ошибка при получении данных')
      }
    },
    async getManagers() {
      try {
        const { data } = await this.$api.get('/v1/staff/list')
        this.managerList = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить информацию о менеджерах')
      }
    },
    async getClients() {
      try {
        const { data } = await this.$api.get('/v1/client/list')
        this.clientList = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить информацию о клиентах')
      }
    },
    async getVendors() {
      try {
        const { data } = await this.$api.get('/v1/vendor/list')
        this.vendorList = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить список поставщиков')
      }
    },
    createEvent() {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api
        .post(`/v1/events/create`, this.eventData)
        .then(() => {
          this.$toast('success', 'Сделка добавлена')
          this.$emit('success')
        })
        .catch(e => {
          this.$catch(e, 'Ошибка обновления данных')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    changeCanView() {
      this.eventData.event.canView = this.multiselect.canView.map(item => item.id)
    },
    changeCanEdit() {
      this.eventData.event.canEdit = this.multiselect.canEdit.map(item => item.id)
    },
    selectVendor(vendor) {
        this.eventData.event.vendorId = vendor.id
    },
    removeVendor() {
        this.eventData.event.vendorId = null
    }
  },
  async mounted() {
    await Promise.all([
      this.getEventStatuses(),
      this.getManagers(),
      this.getClients(),
      this.getVendors()
    ])
      .then(() => {
        // MultiSelect :: map clients
        this.multiselect.clients.push({
          id: 0,
          company: '+ Новый клиент'
        })

        this.multiselect.clients = this.multiselect.clients.concat(
          this.clientList.map(client => {
            return {
              id: client.id,
              company: client.contact.company
            }
          })
        )

        // MultiSelect :: map managers
        this.multiselect.managers = this.managerList.map(manager => {
          return {
            id: manager.id,
            name: manager.contact.name
          }
        })

        // Multiselect :: map event statuses
        this.multiselect.eventStatuses = this.eventStatuses.map(status => {
          return {
            id: status.id,
            name: status.name
          }
        })

        // Multiselect :: map vendors
        this.multiselect.vendors = this.vendorList.map(vendor => {
          return {
            id: vendor.id,
            name: vendor.contact.company
          }
        })
      })
      .then(() => {
        this.componentIsReady = true
      })
  }
}
</script>
