<template ref="popup">
  <div class="confirmation-dialog" v-if="isVisible">
    <div class="confirmation-dialog__overlay" @click="_cancel" />
    <div class="confirmation-dialog__window">
      <div class="confirmation-dialog__heading">
        <h3 v-text="dialogData.title" />
      </div>

      <div class="confirmation-dialog__body">
        {{ dialogData.content }}
      </div>

      <div class="confirmation-dialog__buttons">
        <button class="ui-button" @click="_confirm">Подтвердить</button>
        <button class="ui-button ui-button__gray" @click="_cancel">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogData: {
        title: '',
        content: ''
      },
      isVisible: false
    }
  },
  methods: {
    show(opts = {}) {
      this.dialogData.title = opts.title
      this.dialogData.content = opts.content

      this.isVisible = true

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm() {
      this.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User canceled the dialogue'))
    },

    close() {
      this.isVisible = false
    }
  }
}
</script>
