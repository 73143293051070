<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window">
      <div class="ui-modal__heading">
        <h3>Редактирование поступления</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form class="ui-modal__form" @submit.prevent="updateDebit">
        <div class="ui-form-group">
          <label class="ui-label">Дата</label>
          <VueDatePicker locale="ru" v-model="debit.createdAt" />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">Название поступления</label>
          <input
            type="text"
            class="ui-input"
            placeholder="Название поступления"
            v-model="debit.name"
            required
          />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">Сумма поступления</label>
          <input
            type="number"
            class="ui-input"
            placeholder="Сумма поступления"
            v-model="debit.sum"
            min="0"
            step="0.01"
            required
          />
        </div>
        <div class="ui-form-group" v-if="multiselect.debitStatuses.length">
          <label class="ui-label">Статус</label>
          <MultiSelect
            v-model="multiselect.debitStatusObject"
            tag-placeholder="Выберите статус"
            placeholder="Выберите статус"
            label="name"
            track-by="id"
            :options="multiselect.debitStatuses"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :searchable="false"
            :allow-empty="false"
            :showLabels="false"
            @select="selectDebitStatus"
            @remove="removeDebitStatus"
            required
          />
        </div>
        <button type="submit" class="ui-button" v-text="'Сохранить'" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['debitId'],
  emits: ['closeModal', 'success'],
  data() {
    return {
      multiselect: {
        debitStatuses: [],
        debitStatusObject: null
      },
      debit: {
        id: this.$props.debitId,
        name: null,
        sum: null,
        debitStatusId: null,
        createdAt: Date.now()
      },
      debitStatuses: [],
      isLoading: false
    }
  },
  methods: {
    selectDebitStatus(status) {
      this.debit.debitStatusId = status.id
    },
    removeDebitStatus() {
      this.debit.debitStatusId = null
    },
    async getDebitStatuses() {
      try {
        const { data } = await this.$api.get('/v1/debit-status/list')
        this.debitStatuses = data
      } catch (e) {
        this.$catch(e, 'Ошибка при получении данных')
      }
    },
    // createDebit() {
    //     if (this.isLoading) return false
    //     this.isLoading = true

    //     this.$api
    //         .post(`/debit/create`, this.debit)
    //         .then(() => {
    //             this.$toast('success', 'Поступление добавлено')
    //             this.$emit('success')
    //         })
    //         .catch(e => {
    //             this.$catch(e, 'Ошибка добавления данных')
    //         })
    //         .finally(() => {
    //             this.isLoading = false
    //         })
    // },
    updateDebit() {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api.post(`/v1/debit/update/${this.$props.debitId}`, this.debit)
        .then(() => {
          this.$toast('success', 'Поступление обновлено')
          this.$emit('success')
        })
        .catch(e => {
          this.$catch(e, 'Ошибка обновления данных')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async getDebit() {
      try {
        const { data } = await this.$api.get(`/v1/debit/get/${this.$props.debitId}`)
        this.debit.id = data.id
        this.debit.name = data.name
        this.debit.sum = data.sum
        this.debit.debitStatusId = data.debitStatusId
        this.debit.createdAt = data.createdAt
      } catch (e) {
        this.$toast(e, 'Не удалось получить данные о поступлении')
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getDebitStatuses(),
      this.getDebit()
    ])
      .then(() => {
        // Multiselect :: map statuses
        this.multiselect.debitStatuses = this.debitStatuses.map(status => {
          return {
            id: status.id,
            name: status.name
          }
        })

        this.multiselect.debitStatusObject = this.multiselect.debitStatuses.filter(a => a.id === this.debit.debitStatusId)
      })
  }
}
</script>
