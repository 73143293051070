import {defineStore} from 'pinia'
import { useStorage } from '@vueuse/core'

export const useNotificationsStore = defineStore('user', {
    state: () => {
        return {
            statistics: useStorage('statistics', false),
            notifications: useStorage('notifications', false)
        }
    }
})
