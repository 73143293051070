import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { toast } from 'vue3-toastify'
import axios from 'axios'
import moment from 'moment'
// import Popper from 'vue3-popper'
import VueDatePicker from '@vuepic/vue-datepicker';
import { MaskInput } from 'vue-3-mask';
import Multiselect from 'vue-multiselect';
import JsonExcel from "vue-json-excel3";

import router from './router'

import App from './App.vue'

import 'vue3-toastify/dist/index.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import '@/assets/scss/styles.scss';

const app = createApp(App)
app.component('MaskInput', MaskInput);
app.component('VueDatePicker', VueDatePicker);
app.component('MultiSelect', Multiselect);
app.component("downloadExcel", JsonExcel);
app.use(createPinia())
app.use(router)

// Вспомогательные функции
const convertDateFn = (date) => {
    return moment(date).format('DD-MM-YYYY hh:mm')
}
app.config.globalProperties.$convertDate = convertDateFn;

// User store
import { useUserStore } from './stores/user'
const userStore = useUserStore()
app.config.globalProperties.$userStore = userStore

const checkPermissions = (ids) => {
    if (!userStore.roleId || !ids || !ids.length) return false;
    if (ids.indexOf(parseInt(userStore.roleId)) > -1) return true;
    return false
}
app.config.globalProperties.$checkPermissions = checkPermissions

import { useNotificationsStore } from './stores/notifications'
const notificationsStore = useNotificationsStore()
app.config.globalProperties.$notificationsStore = notificationsStore

const backendUrl = 'https://api.sakora.ru'
app.config.globalProperties.$backendUrl = backendUrl;

// Axios instance
const updateAxiosInstance = () => {
    const axiosInstance = axios.create({
        baseURL: `${backendUrl}/api`,
        headers: {
            'Content-Type': 'application/json',
            'token': app.config.globalProperties.$userStore.token
        }
    })
    app.config.globalProperties.$api = axiosInstance
}
app.config.globalProperties.$updateAxiosInstance = updateAxiosInstance

updateAxiosInstance()

// Toasts

app.config.globalProperties.$catch = (e, message) => {
    const params = {autoclose: 2000}

    if (typeof e.response === 'undefined') {
        toast.error('Произошла непредвиденная ошибка', params)
        return
    }

    if (e.response.status !== 500) {
        toast.error(message, params)
    }

    return
}

app.config.globalProperties.$toast = (type, message) => {
    const params = {autoclose: 2000}

    if (type === 'success') {
        toast.success(message, params)
    }

    if (type === 'error') {
        toast.error(message, params)
    }
}

const convertToCurrency = (number) => {
    const currency = ' ₽';
    if (!number) return 0 + currency;
    return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + currency
}
app.config.globalProperties.$convertToCurrency = convertToCurrency

app.mount('#app')
