<template>
  <div class="db-main-view">
    <div class="db-main-view__buttons">
      <div class="db-main-view__buttons-group">
        <div>
          <input
            type="text"
            class="ui-input"
            placeholder="Поиск"
            v-model="filters.searchText"
            @keyup="filterEvents"
          />
        </div>
        <div class="db-main-view__filters">
          <button
            class="ui-button db-main-view__filters-btn"
            v-text="
              `Фильтры (${
                filters.eventStatuses.length + filters.creditStatuses.length
              })`
            "
            @click.prevent="filters.isOpen = !filters.isOpen"
          />

          <div class="db-main-view__filters-container" v-if="filters.isOpen">
            <div
              class="db-main-view__filters-container__group"
              v-if="filtersData.eventStatuses.length"
            >
              <p class="db-main-view__filters-container__group-name">
                Статус события:
              </p>
              <div
                class="ui-checkbox-group"
                v-for="status in filtersData.eventStatuses"
                :key="status.id"
              >
                <input
                  type="checkbox"
                  v-model="filters.eventStatuses"
                  :value="status.id"
                  :id="`event_status_${status.id}`"
                  @change="filterEvents"
                />
                <label
                  :for="`event_status_${status.id}`"
                  v-text="status.name"
                />
              </div>
            </div>

            <div
              class="db-main-view__filters-container__group"
              v-if="filtersData.creditStatuses.length"
            >
              <p class="db-main-view__filters-container__group-name">
                Статус операции:
              </p>
              <div
                class="ui-checkbox-group"
                v-for="status in filtersData.creditStatuses"
                :key="status.id"
              >
                <input
                  type="checkbox"
                  v-model="filters.creditStatuses"
                  :value="status.id"
                  :id="`credit_status_${status.id}`"
                  @change="filterEvents"
                />
                <label
                  :for="`credit_status_${status.id}`"
                  v-text="status.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="db-main-view__buttons-group" v-if="$checkPermissions([1, 2])">
        <button
          class="db-main-view__create-btn"
          @click.prevent="openNewEventModal"
        >
          Создать событие
        </button>
      </div>
    </div>

    <div class="db-main-view__list" v-if="eventsF.length">
      <div
        v-for="e in eventsF"
        :key="e.id"
        :class="{
          'db-main-view__item': true,
          'db-main-view__item--active': e.isOpen,
        }"
      >
        <!--SingleEvent-->
        <SingleEvent
          :key="e.id"
          :event="e"
          @openEvent="e.isOpen = !e.isOpen"
          @updateEvent="updateEvents()"
        />

        <!--Debits and credits-->
        <SingleEventOperations v-if="e.isOpen" @updateEventFromChild="updateEvents()" :eventId="e.id" />
      </div>
    </div>

    <CreateEventModal
      v-if="newEvent.isOpen"
      @closeModal="closeNewEventModal"
      @success="successNewEventModal"
    />
  </div>
</template>

<script>
import SingleEvent from './components/events-list/SingleEvent.vue'
import CreateEventModal from "./components/create-event/CreateEventModal.vue";
import SingleEventOperations from "./components/events-list/SingleEventOperations.vue";

export default {
  components: {
    SingleEvent,
    SingleEventOperations,
    CreateEventModal
  },
  data() {
    return {
      eventsF: [],
      events: [],

      // filters
      filters: {
        searchText: "",
        eventStatuses: [],
        creditStatuses: [],
        isOpen: false,
      },
      filtersData: {
        eventStatuses: [],
        creditStatuses: [],
      },

      // new event
      newEvent: {
        isOpen: false,
      },
    }
  },
  methods: {
    async getEvents() {
      try {
        const { data } = await this.$api.get('/v2/event/list')
        data.map(item => { item.isOpen = false })
        this.events = data
        this.filterEvents()
      } catch (e) {
        this.$catch(e, 'Не удалось получить данные о событиях с сервера')
      }
    },
    filterEvents() {
      this.eventsF = this.events;

      if (this.filters.searchText.length > 1) {
        this.eventsF = this.eventsF.filter((item) => {
          if (item.name.toLowerCase().indexOf(this.filters.searchText.toLowerCase()) > -1) return item;
        });
      }

      if (this.filters.eventStatuses.length) {
        this.eventsF = this.eventsF.filter((item) => {
          if (this.filters.eventStatuses.indexOf(item.status.id) > -1) return item;
        });
      }

      if (this.filters.creditStatuses.length) {
        this.eventsF = this.eventsF.filter((item) => {
          for (let singleCredit of item.credits) {
            if (this.filters.creditStatuses.indexOf(singleCredit.status.id) > -1) return item;
          }
        });
      }
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get("/v1/event-status/list");
        this.filtersData.eventStatuses = data;
      } catch (e) {
        this.$catch(e, "Не удалось получить статусы событий");
      }
    },
    async getCreditStatuses() {
      try {
        const { data } = await this.$api.get("/v1/credit-status/list");
        this.filtersData.creditStatuses = data;
      } catch (e) {
        this.$catch(e, "Не удалось получить статусы операций");
      }
    },
    openNewEventModal() {
      this.newEvent.isOpen = true
    },
    closeNewEventModal() {
      this.newEvent.isOpen = false
    },
    successNewEventModal() {
      this.getEvents()
      this.closeNewEventModal()
    },
    async updateEvents() {
      let openedEventsIds = this.eventsF.filter(e => e.isOpen).map(e => e.id)
      await this.getEvents()
      this.filterEvents()
      this.eventsF.forEach(e => {
        if (openedEventsIds.indexOf(e.id) > -1) e.isOpen = true
      })
    }
  },
  mounted() {
    Promise.all([
      this.getEvents(),
      this.getEventStatuses(),
      this.getCreditStatuses()
    ])
  }
}
</script>