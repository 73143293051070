import { createRouter, createWebHistory } from 'vue-router'

import AuthLayout from '@/layouts/auth/AuthLayout.vue'
import DashboardLayout from '@/layouts/dashboard/DashboardLayout.vue'

import LoginView from '@/pages/auth/LoginView.vue'
import RegisterView from '@/pages/auth/RegisterView.vue'
import PasswordRecoveryView from '@/pages/password-recovery/PasswordRecovery.vue'
import NewPasswordView from '@/pages/password-recovery/NewPassword.vue'

import EventsList from '@/pages/dashboard/events/EventsList.vue'

import UserListView from '@/pages/dashboard/users/list/UserListView.vue'

import StatisticsView from '@/pages/dashboard/statistics/StatisticsView.vue'

import CreditTypesView from '@/pages/dashboard/credit-types/CreditTypesView.vue'

import { useUserStore } from '@/stores/user'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: {
                name: 'account-layout'
            },
            children: [
                {
                    path: 'account',
                    name: 'account-layout',
                    component: AuthLayout,
                    redirect: {
                        name: 'login'
                    },
                    children: [
                        {
                            path: 'login',
                            name: 'login',
                            component: LoginView,
                            meta: {
                                requiresAuth: false,
                                title: 'Авторизация'
                            }
                        },
                        {
                            path: 'register',
                            name: 'register',
                            component: RegisterView,
                            meta: {
                                requiresAuth: false,
                                title: 'Регистрация'
                            }
                        },
                        {
                            path: 'password-recovery',
                            name: 'password-recovery',
                            component: PasswordRecoveryView,
                            meta: {
                                requiresAuth: false,
                                title: 'Восстановление пароля'
                            }
                        },
                        {
                            path: 'new-password/:secret',
                            name: 'new-password',
                            component: NewPasswordView,
                            meta: {
                                requiresAuth: false,
                                title: 'Восстановление пароля'
                            }
                        }
                    ]
                },
                {
                    path: 'dashboard',
                    name: 'dashboard-layout',
                    component: DashboardLayout,
                    meta: {
                        requiresAuth: true
                    },
                    redirect: {
                        name: 'dashboard-main'
                    },
                    children: [
                        {
                            path: 'credit-types',
                            name: 'credit-types',
                            component: CreditTypesView,
                            meta: {
                                requiresAuth: true,
                                title: 'Типы расходов'
                            }
                        },
                        {
                            path: 'dashboard-main',
                            name: 'dashboard-main',
                            component: EventsList,
                            meta: {
                                requiresAuth: true,
                                title: 'События'
                            }
                        },
                        {
                            path: 'users',
                            name: 'users',
                            component: UserListView,
                            meta: {
                                requiresAuth: true,
                                title: 'Список пользователей'
                            }
                        },
                        {
                            path: 'statistics',
                            name: 'statistics',
                            component: StatisticsView,
                            meta: {
                                requiresAuth: true,
                                title: 'Статистика'
                            }
                        }
                    ]
                }
            ]
        },
        
    ]
})

router.beforeEach((to) => {
    const userStore = useUserStore()
    if(to.meta.requiresAuth && !userStore.token || to.meta.requiresAuth && userStore.token === 'undefined') {
        return {
            path: '/'
        }
    }
})

export default router