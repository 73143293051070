<template>
    <div class="auth-view">
        <img src="@/assets/sakora_logo.svg" class="auth-view__logo">
        <div class="auth-view__container">
            <span class="auth-view__heading">Регистрация</span>

            <form class="auth-view__form" @submit.prevent="register()">
                <input type="email" class="auth-view__input" placeholder="Email" v-model="registerData.email" required />
                <input type="password" class="auth-view__input" placeholder="Пароль" v-model="registerData.password" required />
                <input type="password" class="auth-view__input" placeholder="Повторите пароль" v-model="registerData.password_repeat" required />
                <button type="submit" class="auth-view__button">Регистрация</button>
            </form>

            <div class="auth-view__links">
                <router-link to="#">Восстановить пароль</router-link>
                <router-link :to="{ name: 'login' }">Авторизация</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            registerData: {
                email: null,
                password: null,
                password_repeat: null
            }
        }
    },
    methods: {
        register() {
            if (this.registerData.password !== this.registerData.password_repeat) {
                this.$toast('error', 'Пароль и подтверждение пароля не совпадают')
                return false
            }

            console.log(1)

            this.$api.post('/v1/register/create', this.registerData)
            .then((res) => {
                const {data} = res
                
                this.$userStore.email = data.email
                this.$userStore.token = data.token
                this.$userStore.roleId = data.roleId

                this.$updateAxiosInstance()

                this.$router.push('/dashboard')
            })
            .catch(e => {
                console.log(e.response.status)
                if (e.response.status === 501) {
                    this.$toast('error', 'Такой email уже зарегистрирован')
                    return false
                }
                
                this.$catch(e, 'Ошибка регистрации')
            })
        }
    },
    beforeMount() {
        if (this.$userStore.token && this.$userStore.token.length) {
            this.$router.push('/dashboard')
        }
    }
}
</script>