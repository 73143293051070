<template>
    <div class="auth-view">
        <img src="@/assets/sakora_logo.svg" class="auth-view__logo">
        <div class="auth-view__container">
            <span class="auth-view__heading">Авторизация</span>

            <form class="auth-view__form" @submit.prevent="auth">
                <input type="email" class="auth-view__input" placeholder="Email" v-model="authData.email" required />
                <input type="password" class="auth-view__input" placeholder="Password" v-model="authData.password" required />
                <button type="submit" class="auth-view__button">Войти</button>
            </form>

            <div class="auth-view__links">
                <router-link :to="{ name: 'password-recovery' }">Восстановить пароль</router-link>
                <router-link :to="{ name: 'register' }">Регистрация</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            authData: {
                email: null,
                password: null
            }
        }
    },
    methods: {
        auth() {
            this.$api.post('/v1/auth/auth', this.authData)
            .then((res) => {
                const {data} = res
                this.$userStore.email = data.email
                this.$userStore.token = data.token
                this.$userStore.roleId = data.roleId

                this.$updateAxiosInstance()

                this.$router.push('/dashboard')
            })
            .catch(e => {
                this.$catch(e, 'Неверный логин или пароль')
            })
        }
    },
    beforeMount() {
        if (this.$userStore.token && this.$userStore.token !== 'undefined' && this.$userStore.token.length) {
            this.$router.push('/dashboard')
        }
    }
}
</script>