<template>
    <div class="auth-view">
        <img src="@/assets/sakora_logo.svg" class="auth-view__logo">
        <div class="auth-view__container" v-if="completed">
            <span class="auth-view__heading">Ссылка отправлена</span>
            <p>Ссылка для сброса пароля отправлена на ваш email!</p>
        </div>

        <div class="auth-view__container" v-else>
            <span class="auth-view__heading">Сбросить пароль</span>

            <form class="auth-view__form" @submit.prevent="send">
                <input type="email" class="auth-view__input" placeholder="Email" v-model="recoveryData.email" required />
                <button type="submit" class="auth-view__button">Сбросить пароль</button>
            </form>

            <div class="auth-view__links">
                <router-link :to="{ name: 'login' }">Авторизация</router-link>
                <router-link :to="{ name: 'register' }">Регистрация</router-link>
            </div>
        </div>        
    </div>
</template>

<script>
export default {
    data() {
        return {
            recoveryData: {
                email: null
            },
            completed: false
        }
    },
    methods: {
        send() {
            this.$api
                .post('/v1/password-recovery/recovery', this.recoveryData)
                .then(() => {
                    this.$toast('success', 'Ссылка на восстановление пароля отправлена вам на почту')
                    this.completed = true
                })
                .catch(e => {
                    console.log(e)
                    this.$catch(e, 'Ошибка сброса пароля')
                })
        }
    }
}
</script>