<template>
    <div class="auth-view">
        <img src="@/assets/sakora_logo.svg" class="auth-view__logo">
        <div class="auth-view__container">
            <span class="auth-view__heading">Новый пароль</span>

            <form class="auth-view__form" @submit.prevent="send">
                <input type="password" class="auth-view__input" placeholder="Пароль" v-model="recoveryData.password" required />
                <input type="password" class="auth-view__input" placeholder="Пароль еще раз" v-model="recoveryData.password_repeat" required />
                <button type="submit" class="auth-view__button">Сбросить пароль</button>
            </form>

            <div class="auth-view__links">
                <router-link :to="{ name: 'login' }">Авторизация</router-link>
                <router-link :to="{ name: 'register' }">Регистрация</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            recoveryData: {
                password: null,
                password_repeat: null
            }
        }
    },
    methods: {
        send() {
            if (this.recoveryData.password !== this.recoveryData.password_repeat) {
                this.$toast('Пароли не совпадают')
                return false
            }

            this.$api
                .post(`/v1/password-recovery/new-password/${this.$route.params.secret}`, this.recoveryData)
                .then(() => {
                    this.$toast('success', 'Пароль успешно сброшен')
                    this.$router.push('/account/login')
                })
                .catch(e => {
                    console.log(e)
                    this.$catch(e, 'Ошибка сброса пароля')
                })
        }
    }
}
</script>