<template>
  <div class="db-main-view__table-container" v-if="credits && credits.length">
    <div class="db-main-view__table-filters">
      <div>
        <label class="ui-label">Фильтр по датам</label>
        <VueDatePicker
          locale="ru"
          @update:model-value="applyDateRange"
          range
          :multi-calendars="{ solo: true }"
          v-model="filters.dates"
        />
      </div>
      <div>
        <label class="ui-label">Поиск по событиям</label>
        <input v-model="searchText" @keyup="filterData" type="text" placeholder="Название события" class="ui-input" />
      </div>
    </div>

    <div class="db-main-view__table db-main-view__table-heading">
      <div class="db-main-view__table-details">
        <span class="db-main-view__table-date" v-text="'Дата'" />
        <span class="db-main-view__table-name" v-text="'Операция'" />
        <span class="db-main-view__table-receipt" v-text="'Документы'" />
        <span class="db-main-view__table-sum" v-text="'Приход'" />
        <span class="db-main-view__table-sum" v-text="'Расход'" />
        <span class="db-main-view__table-date" v-text="'Статус'" />
      </div>
      <div class="db-main-view__table-buttons" />
    </div>

    <div class="db-main-view__table" v-for="item in dataFiltred" :key="item.type + '_' + item.data.id">
      <div class="db-main-view__table-details" v-if="item.type === 'credit'">
        <span class="db-main-view__table-date">
          {{ $convertDate(item.data.createdAt) }}
        </span>
        <span class="db-main-view__table-name" v-text="item.data.name" />
        <span class="db-main-view__table-receipt">
          <a
            :href="`${$backendUrl}/${item.data.attachment.file}`"
            v-if="item.data.attachment"
            target="blank"
            download
            v-text="'Скачать документ'"
          />
        </span>
        <span class="db-main-view__table-sum">
          &nbsp;
        </span>
        <span class="db-main-view__table-sum">
          {{ "-" + $convertToCurrency(item.data.sum) }}
        </span>
        <span
          :class="{
            'db-main-view__table-date': true,
            red: item.data.status.name === 'На исполнении',
            green: item.data.status.name === 'Исполнен',
          }"
          v-text="item.data.status.name"
        />
      </div>
      <div class="db-main-view__table-buttons" v-if="item.type === 'credit'" v-show="item.data.editable">
        <div class="db-main-view__table-delete" @click="deleteCredit(item.data.id)" />
        <div
          class="db-main-view__table-edit"
          @click="openEditCreditModal(item.data.id)"
        />
      </div>

      <div class="db-main-view__table-details" v-if="item.type === 'debit'">
        <span class="db-main-view__table-date">
          {{ $convertDate(item.data.createdAt) }}
        </span>
        <span class="db-main-view__table-name">
          {{ item.data.name }}
        </span>
        <span class="db-main-view__table-receipt"> &nbsp; </span>
        <span class="db-main-view__table-sum">
          {{ $convertToCurrency(item.data.sum) }}
        </span>
        <span class="db-main-view__table-sum">
          &nbsp;
        </span>
        <span
          :class="{
            'db-main-view__table-date': true,
            red: item.data.status.name === 'На исполнении',
            green: item.data.status.name === 'Исполнен',
          }"
          v-text="item.data.status.name"
        />
      </div>
      <div class="db-main-view__table-buttons" v-if="item.type === 'debit'" v-show="item.data.editable">
        <div
          class="db-main-view__table-delete"
          @click.stop="deleteDebit(item.data.id)"
        />
        <div
          class="db-main-view__table-edit"
          @click.stop="openEditDebitModal(item.data.id)"
        />
      </div>
    </div>
  </div>

  <div class="db-main-view__item-actions">
    <button
      class="ui-button db-main-view__item-add-credit"
      @click.prevent="openNewCreditModal()"
      v-text="'+ Добавить расход'"
    />
    <button
      class="ui-button db-main-view__item-add-credit"
      @click.prevent="openNewDebitModal()"
      v-text="'+ Добавить поступление'"
    />
  </div>

  <EditCreditModal
    v-if="editableCredit.isOpen"
    :creditId="editableCredit.id"
    @closeModal="closeEditCreditModal"
    @success="successEditCreditModal"
  />
  <CreateCreditModal
    v-if="newCredit.isOpen"
    :eventId="newCredit.eventId"
    @closeModal="closeNewCreditModal"
    @success="successNewCreditModal"
  />
  <CreateDebitModal
    @closeModal="closeNewDebitModal"
    @success="successNewDebitModal"
    :eventId="newDebit.eventId"
    v-if="newDebit.isOpen"
  />
  <EditDebitModal
    @closeModal="closeEditDebitModal"
    @success="successEditDebitModal"
    :debitId="editDebit.id"
    v-if="editDebit.isOpen"
  />

  <!-- confirmation dialog -->
  <ConfirmationDialog ref="confirmationDialog" />
</template>

<script>
import moment from 'moment';
import EditCreditModal from "../edit-credit/EditCreditModal.vue";
import CreateCreditModal from "../create-credit/CreateCreditModal.vue";
import CreateDebitModal from "../create-debit/CreateDebitModal.vue";
import EditDebitModal from "../edit-debit/EditDebitModal.vue";
import ConfirmationDialog from '../../../components/confirmation/ConfirmationDialog.vue';

export default {
  props: ['eventId'],
  emits: ['updateEventFromChild'],
  components: {
    EditCreditModal,
    CreateCreditModal,
    ConfirmationDialog,
    CreateDebitModal,
    EditDebitModal,
  },
  data() {
    return {
      // credits
      credits: [],
      lastCredits: [],
      allowLastCredits: false,
      displayAllCredits: false,

      // Modals
      editableCredit: {
        id: null,
        isOpen: false,
      },
      newCredit: {
        eventId: this.$props.eventId,
        isOpen: false,
      },

      // debits
      debits: [],
      lastDebits: [],
      allowLastDebits: false,
      displayAllDebits: false,

      // Modals
      newDebit: {
        eventId: null,
        isOpen: false,
      },
      editDebit: {
        id: null,
        isOpen: false,
      },

      // all data
      data: [],
      dataFiltred: [],

      // filters
      searchText: '',
      filters: {
        dates: 0
      }
    }
  },
  methods: {
    // credits
    async getCredits() {
      try {
        const { data } = await this.$api(`/v2/credit/get-by-event/${this.$props.eventId}`)
        this.credits = data
      } catch (e) {
        this.$catch(e)
      }
    },
    // processCredits() {
    // if (this.credits.length < 5) return;
    // this.allowLastCredits = true
    // this.lastCredits = this.credits.slice(-5)
    // },
    openEditCreditModal(id) {
      this.editableCredit.id = id
      this.editableCredit.isOpen = true
    },
    closeEditCreditModal() {
      this.editableCredit.id = null
      this.editableCredit.isOpen = false
    },
    async successEditCreditModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeEditCreditModal()
    },
    openNewCreditModal() {
      this.newCredit.isOpen = true
    },
    closeNewCreditModal() {
      this.newCredit.isOpen = false
    },
    async successNewCreditModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeNewCreditModal()
    },
    async deleteCredit(id) {
      // confirmation dialog
      const ok = await this.$refs.confirmationDialog.show({
        title: 'Подтвердите удаление',
        content: 'Вы действительно хотите удалить расход?'
      })

      if (!ok) return;

      try {
        await this.$api.delete(`/v2/credit/delete/${id}`);
        this.$notificationsStore.statistics = true;
        await this.$emit('updateEventFromChild')
        await this.processEventData()
      } catch (e) {
        this.$toast("error", "Недостаточно прав для удаления объекта");
      }
    },

    // debits
    async getDebits() {
      try {
        const { data } = await this.$api.get(`/v2/debit/get-by-event/${this.$props.eventId}`)
        this.debits = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить поступления')
      }
    },
    // processDebits() {
    //   if (this.debits.length > 5) {
    //     this.lastDebits = this.debits.slice(-5)
    //     this.allowLastDebits = true
    //   }
    // },
    async deleteDebit(id) {
      // confirmation dialog
      const ok = await this.$refs.confirmationDialog.show({
        title: 'Подтвердите удаление',
        content: 'Вы действительно хотите удалить поступление?'
      })

      if (!ok) return;

      try {
        await this.$api.delete(`/v1/debit/delete/${id}`);
        this.$notificationsStore.statistics = true;
        await this.$emit('updateEventFromChild')
        await this.processEventData()
      } catch (e) {
        this.$toast("error", "Недостаточно прав для удаления объекта");
      }
    },
    openEditDebitModal(id) {
      this.editDebit.id = id
      this.editDebit.isOpen = true
    },
    closeEditDebitModal() {
      this.editDebit.id = null
      this.editDebit.isOpen = false
    },
    async successEditDebitModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeEditDebitModal()
      
    },
    // new debit
    openNewDebitModal() {
      this.newDebit.eventId = this.$props.eventId
      this.newDebit.isOpen = true
    },
    closeNewDebitModal() {
      this.newDebit.eventId = null
      this.newDebit.isOpen = false
    },
    async successNewDebitModal() {
      await this.$emit('updateEventFromChild')
      await this.processEventData()
      this.closeNewDebitModal()
    },

    // make data
    makeData() {
      this.credits.forEach(credit => {
        this.data.push({
          type: 'credit',
          data: credit
        })
      })

      this.debits.forEach(debit => {
        this.data.push({
          type: 'debit',
          data: debit
        })
      })


      this.data.sort(
        (a, b) => {
          const dateA = +new Date(moment(a.data.createdAt).format()).getTime() / 1000
          const dateB = +new Date(moment(b.data.createdAt).format()).getTime() / 1000
          // console.log(dateA)

          if(parseInt(dateA) > parseInt(dateB)) {
              return 1
          }

          if(parseInt(dateA) < parseInt(dateB)) {
              return -1
          }

          return 0
        }
      )

      this.dataFiltred = this.data
    },

    // filter data
    filterData() {
      if(this.searchText.length) {
        this.dataFiltred = this.data.filter(item => item.data.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
      } else {
        this.dataFiltred = this.data
      }

      if (!this.filters.dates) return
      this.dataFiltred = 
        this.dataFiltred.filter(
          e => new Date(e.data.createdAt).getTime() >= new Date(this.filters.dates[0]).getTime() && 
          new Date(e.data.createdAt).getTime() <= new Date(this.filters.dates[1]).getTime()
        )
    },
    applyDateRange(d) {
      if (!d) {
        this.filters.dates = 0
      } else {
        this.filters.dates = [d[0], d[1]]
      }
      
      this.filterData()
    },
    async processEventData() {
      this.data = []
      this.dataFiltred = []

      await Promise.all([
        this.getCredits(),
        this.getDebits()
      ])
      this.makeData()
      this.filterData()
    }
  },
  async mounted() {
    await this.processEventData()
  }
}
</script>
