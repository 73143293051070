<template>
    <div class="ui-modal">
        <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
        <div class="ui-modal__window">
            <div class="ui-modal__heading">
                <h3>Добавление пользователя</h3>
                <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
            </div>

            <form class="ui-modal__form" @submit.prevent="createUser">
                <div class="ui-form-group" v-if="userRoles.length">
                    <label class="ui-label">Роль</label>
                    <select
                        v-model="user.roleId"
                        class="ui-input"
                        required
                    >
                        <option
                            v-for="role in userRoles"
                            :key="role.id"
                            :value="role.id"
                            :text="role.name"
                        />
                    </select>
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Имя</label>
                    <input
                        type="text"
                        class="ui-input"
                        placeholder="Имя"
                        v-model="user.name"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Email</label>
                    <input
                        type="email"
                        class="ui-input"
                        placeholder="Email"
                        v-model="user.email"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Номер телефона</label>
                    <MaskInput
                        mask="+# (###) ###-##-##"
                        class="ui-input"
                        placeholder="Номер телефона"
                        v-model="user.phone"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Пароль</label>
                    <input
                        type="password"
                        class="ui-input"
                        placeholder="Пароль"
                        v-model="user.password"
                        minlength="6"
                        required
                    />
                </div>
                <div class="ui-form-group" v-if="user.roleId === 3 || user.roleId === 4">
                    <label class="ui-label">Название компании</label>
                    <input
                        type="text"
                        class="ui-input"
                        placeholder="Название компании"
                        v-model="user.company"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Адрес</label>
                    <input
                        type="text"
                        class="ui-input"
                        placeholder="Адрес"
                        v-model="user.address"
                        required
                    />
                </div>

                <button type="submit" class="ui-button" v-text="'Сохранить'" />
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                roleId: null,
                email: null,
                password: null,
                name: null,
                phone: null,
                address: null,
                company: null
            },
            userRoles: [],
            isLoading: false
        }
    },
    methods: {
        async getUserRoles() {
            try {
                const {data} = await this.$api.get('/v1/user-role/list')
                this.userRoles = data
            } catch(e) {
                this.$catch(e, 'Ошибка при получении данных')
            }
        },
        createUser() {
            if (this.isLoading) return false
            this.isLoading = true

            this.$api
                .post(`/v1/user/create`, this.user)
                .then(() => {
                    this.$toast('success', 'Пользователь добавлен')
                    this.$emit('success')
                })
                .catch(e => {
                    this.$catch(e, 'Ошибка добавления пользователя')
                })
                .finally(() => {
                    this.isLoading = false
                })
        }
    },
    async mounted() {
        await this.getUserRoles()
    }
}
</script>
