<template>
  <div class="ui-modal">
    <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
    <div class="ui-modal__window" v-show="componentIsReady">
      <div class="ui-modal__heading">
        <h3>Редактирование события</h3>
        <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
      </div>

      <form
        class="ui-modal__form"
        autocomplete="off"
        @submit.prevent="updateEvent"
      >
        <div class="ui-form-group">
          <label class="ui-label">Дата</label>
          <VueDatePicker locale="ru" v-model="event.createdAt" />
        </div>
        <div class="ui-form-group">
          <label class="ui-label">Название события</label>
          <input
            type="text"
            class="ui-input"
            placeholder="Название события"
            v-model="event.name"
          />
        </div>
        <div
          class="ui-form-group"
          v-if="managerList.length && $userStore.roleId == 1"
        >
          <label class="ui-label">Менеджер</label>
          <MultiSelect
            v-model="multiselect.managerObject"
            tag-placeholder="Выберите менеджера"
            placeholder="Начните вводить имя менеджера"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            :showLabels="false"
            @select="selectManager"
            @remove="removeManager"
            required
          />
        </div>

        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">Наблюдатели</label>
          <MultiSelect
            v-model="multiselect.canView"
            tag-placeholder="Кто может просматривать событие"
            placeholder="Начните вводить имя сотрудника"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            :multiple="true"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="changeCanView"
            @remove="changeCanView"
            required
          />
        </div>
        <div
          class="ui-form-group"
          v-if="multiselect.managers.length && $userStore.roleId == 1"
        >
          <label class="ui-label">Редакторы</label>
          <MultiSelect
            v-model="multiselect.canEdit"
            tag-placeholder="Кто может редактировать событие"
            placeholder="Начните вводить имя сотрудника"
            label="name"
            track-by="id"
            :options="multiselect.managers"
            :multiple="true"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="changeCanEdit"
            @remove="changeCanEdit"
            required
          />
        </div>

        <div class="ui-form-group" v-if="eventStatuses.length">
          <label class="ui-label">Статус события</label>
          <MultiSelect
            v-model="multiselect.eventStatusObject"
            tag-placeholder="Выберите статус"
            placeholder="Выберите статус"
            label="name"
            track-by="id"
            :options="multiselect.eventStatuses"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :searchable="false"
            :allow-empty="false"
            :showLabels="false"
            @select="selectEventStatus"
            @remove="removeEventStatus"
            required
          />
        </div>
        <div class="ui-form-group" v-if="multiselect.vendors.length">
          <label class="ui-label">Поставщик</label>
          <MultiSelect
            v-model="multiselect.vendorObject"
            tag-placeholder="Выберите поставщика"
            placeholder="Начните вводить имя поставщика"
            label="name"
            track-by="id"
            :options="multiselect.vendors"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            @select="selectVendor"
            @remove="removeVendor"
            required
          />
        </div>
        <div class="ui-form-group" v-if="clientList.length">
          <label class="ui-label">Клиент</label>
          <MultiSelect
            v-model="multiselect.clientObject"
            tag-placeholder="Выберите клиента"
            placeholder="Начните вводить название клиента"
            label="company"
            track-by="id"
            :options="multiselect.clients"
            :multiple="false"
            :selectLabel="'Выбрать'"
            :deselectLabel="'Отменить'"
            :selectedLabel="'Выбрано'"
            :allow-empty="false"
            :showLabels="false"
            @select="selectClient"
            @remove="removeClient"
            required
          />
        </div>
        <button type="submit" class="ui-button" v-text="'Сохранить'" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['eventId'],
  // components: {Datepicker},
  data() {
    return {
      multiselect: {
        clientObject: null,
        clients: [],
        managerObject: null,
        managers: [],
        eventStatusObject: null,
        eventStatuses: [],
        vendors: [],
        vendorObject: null,
        canView: [],
        canEdit: []
      },
      event: {
        id: null,
        name: null,
        managerId: null,
        eventStatusId: null,
        clientId: null,
        vendorId: null,
        createdAt: null,
        canView: null,
        canEdit: null
      },
      activeFields: {
        email: true,
        password: true,
        name: true,
        phone: true,
        address: true,
        company: true
      },
      eventStatuses: [],
      clientList: [],
      managerList: [],
      vendorList: [],
      isLoading: false,
      componentIsReady: false
    }
  },
  methods: {
    changeCanView() {
      this.event.canView = this.multiselect.canView.map(a => a.id)
    },
    applyCanView() {
      this.multiselect.canView = []

      if (!this.event.canView) {
        this.event.canView = []
        return;
      }

      this.multiselect.managers.forEach(manager => {
        if (this.event.canView.indexOf(manager.id) > -1) {
          this.multiselect.canView.push(manager)
        }
      })
    },
    changeCanEdit() {
      this.event.canEdit = this.multiselect.canEdit.map(a => a.id)
    },
    applyCanEdit() {
      this.multiselect.canEdit = []

      if (!this.event.canEdit) {
        this.event.canEdit = []
        return;
      }

      this.multiselect.managers.forEach(manager => {
        if (this.event.canEdit.indexOf(manager.id) > -1) {
          this.multiselect.canEdit.push(manager)
        }
      })
    },
    async getEventStatuses() {
      try {
        const { data } = await this.$api.get('/v1/event-status/list')
        this.eventStatuses = data
      } catch (e) {
        this.$catch(e, 'Ошибка при получении данных')
      }
    },
    async getManagers() {
      try {
        const { data } = await this.$api.get('/v1/staff/list')
        this.managerList = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить информацию о менеджерах')
      }
    },
    async getClients() {
      try {
        const { data } = await this.$api.get('/v1/client/list')
        this.clientList = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить информацию о клиентах')
      }
    },
    updateEvent() {
      if (this.isLoading) return false
      this.isLoading = true

      this.$api
        .post(`/v1/events/update/${this.event.id}`, this.event)
        .then(() => {
          this.$toast('success', 'Данные обновлены')
          this.$emit('success')
        })
        .catch(e => {
          this.$catch(e, 'Ошибка обновления данных')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async getEvent() {
      try {
        const { data } = await this.$api.get(`/v1/events/get/${this.$props.eventId}`)

        this.event.id = data.id
        this.event.name = data.name
        this.event.managerId = data.manager.id
        this.event.eventStatusId = data.status.id
        this.event.clientId = data.client.id
        this.event.vendorId = data.vendor?.id ?? null
        this.event.canView = data.canView
        this.event.canEdit = data.canEdit
        this.event.createdAt = data.createdAt

      } catch (e) {
        this.$catch(e, 'Не удалось получить данные о событии')
      }
    },
    async getVendors() {
      try {
        const { data } = await this.$api.get('/v1/vendor/list')
        this.vendorList = data
      } catch (e) {
        this.$catch(e, 'Не удалось получить данные о поставщиках')
      }
    },
    selectClient(client) {
      this.event.clientId = client.id
    },
    removeClient() {
      this.event.clientId = null
    },
    selectManager(manager) {
      this.event.managerId = manager.id
    },
    removeManager() {
      this.event.managerId = null
    },
    selectEventStatus(status) {
      this.event.eventStatusId = status.id
    },
    removeEventStatus() {
      this.event.eventStatusId = null
    },
    selectVendor(vendor) {
      this.event.vendorId = vendor.id
    },
    removeVendor() {
      this.event.vendorId = null
    }
  },
  async mounted() {
    await Promise.all([
      this.getEventStatuses(),
      this.getManagers(),
      this.getClients(),
      this.getEvent(),
      this.getVendors()
    ])
      .then(() => {
        // Multiselect :: map clients
        this.multiselect.clients = this.clientList.map(client => {
          return {
            id: client.id,
            company: client.contact.company
          }
        })

        this.multiselect.clientObject = this.multiselect.clients.filter(client => {
          if (client.id === this.event.clientId) return client
        })

        if (this.multiselect.clientObject.length) this.multiselect.clientObject = this.multiselect.clientObject[0]

        // Multiselect :: map managers
        this.multiselect.managers = this.managerList.map(manager => {
          return {
            id: manager.id,
            name: manager.contact.name
          }
        })

        this.multiselect.managerObject = this.multiselect.managers.filter(manager => {
          if (manager.id === this.event.managerId) return manager
        })

        if (this.multiselect.managerObject.length) this.multiselect.managerObject = this.multiselect.managerObject[0]

        // Multiselect :: event statuses
        this.multiselect.eventStatuses = this.eventStatuses.map(status => {
          return {
            id: status.id,
            name: status.name
          }
        })

        this.multiselect.eventStatusObject = this.multiselect.eventStatuses.filter(status => {
          if (status.id === this.event.eventStatusId) return status
        })

        if (this.multiselect.eventStatusObject.length) this.multiselect.eventStatusObject = this.multiselect.eventStatusObject[0]

        this.applyCanEdit()
        this.applyCanView()

        // Multiselect :: map vendors
        this.multiselect.vendors = this.vendorList.map(vendor => {
          return {
            id: vendor.id,
            name: vendor.contact.company
          }
        })

        // Multiiselect :: map selected vendor
        if (!this.event.vendorId) {
          this.multiselect.vendorObject = null
        } else {
          this.multiselect.vendorObject = this.multiselect.vendors.filter(vendor => {
            if (vendor.id === this.event.vendorId) return vendor
          })
        }
      })
      .then(() => {
        this.componentIsReady = true
      })
  }
}
</script>
