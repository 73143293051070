<template>
    <div class="ui-modal">
        <div class="ui-modal__background" @click.prevent="$emit('closeModal')" />
        <div class="ui-modal__window">
            <div class="ui-modal__heading">
                <h3>Редактирование пользователя</h3>
                <div class="ui-modal__close" @click.prevent="$emit('closeModal')" />
            </div>

            <form class="ui-modal__form" @submit.prevent="updateUser">
                <div class="ui-form-group">
                    <label class="ui-label">Имя</label>
                    <input
                        type="text"
                        class="ui-input"
                        placeholder="Имя"
                        v-model="user.contact.name"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Email</label>
                    <input
                        type="email"
                        class="ui-input"
                        placeholder="Email"
                        v-model="user.email"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Номер телефона</label>
                    <MaskInput
                        mask="+# (###) ###-##-##"
                        class="ui-input"
                        placeholder="Номер телефона"
                        v-model="user.contact.phone"
                        :value="user.contact.phone"
                        required
                    />
                </div>
                <!-- Только для клиента -->
                <div class="ui-form-group" v-if="user.role.name === 'Клиент' || user.role.name === 'Поставщик'">
                    <label class="ui-label">Название организации</label>
                    <input
                        type="text"
                        class="ui-input"
                        placeholder="Название организации"
                        v-model="user.contact.company"
                        required
                    />
                </div>
                <div class="ui-form-group">
                    <label class="ui-label">Адрес</label>
                    <input
                        type="text"
                        class="ui-input"
                        placeholder="Адрес"
                        v-model="user.contact.address"
                        required
                    />
                </div>

                <button type="submit" class="ui-button" v-text="'Сохранить'" />
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['userId'],
    data() {
        return {
            user: {
                id: null,
                email: null,
                contact: {
                    name: null,
                    phone: '',
                    address: null
                },
                organization: {
                    name: null
                },
                role: {
                    id: null,
                    name: null
                }
            },
            userRoles: [],
            isLoading: false
        }
    },
    methods: {
        updateUser() {
            if (this.isLoading) return false
            this.isLoading = true

            this.$api
                .post(`/v1/user/update/${this.user.id}`, this.user)
                .then(() => {
                    this.$toast('success', 'Данные пользователя обновлены')
                    this.$emit('success')
                })
                .catch(e => {
                    this.$catch(e, 'Ошибка обновления данных о пользователе')
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        // get user data
        async getUser() {
            try {
                let {data} = await this.$api.get(`/v1/user/get/${this.$props.userId}`)
                this.user = data
            } catch(e) {
                this.$catch(e, 'Ошибка получения данных о пользователе. Попробуйте еще раз.')
            }
        }
    },
    async mounted() {
        await this.getUser()
    }
}
</script>
